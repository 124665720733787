import {
  BenefitsSection,
  Cursor,
  FaqsSection,
  FixedShape,
  Footer,
  HeroSection,
  LateralTexts,
  NavbarModal,
  ProjectSection,
  RoadmapSection,
  SideModal,
  TokenizedCommunitySection,
} from '@components';
import {
  useDesktopMediaQuery,
  useLargeDesktopMediaQuery,
  useMobileMediaQuery,
  useTabletMediaQuery,
} from '@hooks';
import {
  BenefitsSectionModel,
  CollectionSectionModel,
  FAQSSectionModel,
  HeroSectionModel,
  LandingPageSanityData,
  MappedFAQsSection,
  ProjectSectionModel,
  RoadmapSectionModel,
  SanityFAQsSection,
  SanityLandingSection,
  TokenizedCommunitySectionModel,
} from '@models';
import {
  benefitsSectionQuery,
  collectionSectionQuery,
  faqsSectionQuery,
  heroSectionQuery,
  landingActiveSectionsQuery,
  projectSectionQuery,
  roadmapSectionQuery,
  tokenizedCommunitySectionQuery,
} from '@services/groq-queries';
import { LandingSectionType } from '@types';
import sanityClient from '@utils/sanity';
import type { GetStaticProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useRef } from 'react';
import ProjectSectionMobile from '../components/sections/project-section/project-section-mobile';
import styles from '../styles/index.module.scss';
import { gsap } from 'gsap';

interface HomePageProps {
  data: LandingPageSanityData;
  sections: { [key: string]: SanityLandingSection };
}

const HomePage: NextPage<HomePageProps> = ({ data, sections }) => {
  const {
    heroSectionData,
    collectionSectionData,
    projectSectionData,
    tokenizedCommunitySectionData,
    benefitsSectionData,
    roadmapSectionData,
    faqsSectionData,
  } = data;
  const ref = useRef<HTMLDivElement>(null);
  const cursorRef = useRef<any>(null);
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const isDesktop = useDesktopMediaQuery();
  const isLargeDesktop = useLargeDesktopMediaQuery();
  const shouldAvoidMovementAnimations = isMobile || isTablet;

  useEffect(() => {
    const hash = window.location.hash;
    if (hash?.length > 0) {
      gsap.to(window, {
        scrollTo: {
          y: hash,
          offsetY: isMobile ? 0 : -100,
        },
      });
    }

    cursorRef.current?.moveTo('-100%', '-100%');

    const onMove = ({ clientX, clientY }: PointerEvent) => {
      cursorRef.current?.moveTo(clientX, clientY);
    };

    window.addEventListener('pointermove', onMove);
    return () => window.removeEventListener('pointermove', onMove);
  }, []);

  return (
    <div ref={ref} className={styles.mainContainer}>
      {!isDesktop ? <Cursor ref={cursorRef} /> : null}
      <FixedShape />
      {!isLargeDesktop ? <LateralTexts /> : null}
      <SideModal benefitsSectionData={benefitsSectionData} />
      <NavbarModal />
      {sections[LandingSectionType.heroSection] && (
        <HeroSection
          shouldAvoidMovementAnimations={shouldAvoidMovementAnimations}
          heroData={heroSectionData}
          collectionData={collectionSectionData}
        />
      )}
      {sections[LandingSectionType.projectSection] &&
        (isMobile || isTablet || isDesktop ? (
          <ProjectSectionMobile
            parentRef={ref}
            isMobile={isMobile}
            isTablet={isTablet}
            projectData={projectSectionData}
          />
        ) : (
          <ProjectSection parentRef={ref} projectData={projectSectionData} />
        ))}
      {sections[LandingSectionType.tokenizedCommunitySection] && (
        <TokenizedCommunitySection
          isTablet={isTablet}
          isDesktop={isDesktop}
          shouldAvoidMovementAnimations={shouldAvoidMovementAnimations}
          tokenizedCommunityData={tokenizedCommunitySectionData}
        />
      )}
      {sections[LandingSectionType.benefitsSection] && (
        <BenefitsSection
          shouldAvoidMovementAnimations={shouldAvoidMovementAnimations}
          benefitsSectionData={benefitsSectionData}
        />
      )}
      {sections[LandingSectionType.roadmapSection] && (
        <RoadmapSection
          parentRef={ref}
          isMobile={isMobile}
          isTablet={isTablet}
          shouldAvoidMovementAnimations={shouldAvoidMovementAnimations}
          roadmapSectionData={roadmapSectionData}
        />
      )}
      {sections[LandingSectionType.faqsSection] && (
        <FaqsSection
          // faqs={(sections[LandingSectionType.faqsSection] as MappedFAQsSection).faqs}
          isMobile={isMobile}
          isTablet={isTablet}
          shouldAvoidMovementAnimations={shouldAvoidMovementAnimations}
          faqsSectionData={faqsSectionData}
        />
      )}

      <Footer />
    </div>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const landingActiveSections: SanityLandingSection[] = await sanityClient.fetch(
    landingActiveSectionsQuery,
  );
  const heroSectionData: HeroSectionModel = await sanityClient.fetch(heroSectionQuery(locale));
  const collectionSectionData: CollectionSectionModel = await sanityClient.fetch(
    collectionSectionQuery(locale),
  );
  const projectSectionData: ProjectSectionModel = await sanityClient.fetch(
    projectSectionQuery(locale),
  );
  const tokenizedCommunitySectionData: TokenizedCommunitySectionModel = await sanityClient.fetch(
    tokenizedCommunitySectionQuery(locale),
  );
  const benefitsSectionData: BenefitsSectionModel = await sanityClient.fetch(
    benefitsSectionQuery(locale),
  );
  const roadmapSectionData: RoadmapSectionModel = await sanityClient.fetch(
    roadmapSectionQuery(locale),
  );
  const faqsSectionData: FAQSSectionModel = await sanityClient.fetch(faqsSectionQuery(locale));

  const sections = landingActiveSections.reduce(function (object, item) {
    // TODO: PLEASE IMPROVE FAQS SECTION MAPPING AND LOCALE TYPE
    if (locale && item._type === 'faqsSection') {
      const mappedItem: MappedFAQsSection = {
        ...item,
        faqs: (item as SanityFAQsSection).faqs?.map((faq) => ({
          ...faq,
          question: faq.question[locale as 'en' | 'de' | 'fr' | 'it'],
          answer: faq.answer[locale as 'en' | 'de' | 'fr' | 'it'],
        })),
      };
      object[item._type] = mappedItem;
    } else object[item._type] = item;
    return object;
  }, {} as { [key: string]: SanityLandingSection });

  const data: LandingPageSanityData = {
    heroSectionData,
    collectionSectionData,
    projectSectionData,
    tokenizedCommunitySectionData,
    benefitsSectionData,
    roadmapSectionData,
    faqsSectionData,
  };

  return {
    props: {
      data,
      sections,
      ...(await serverSideTranslations(locale!, ['common'])),
    },
    revalidate: 1,
  };
};
