import { SerializedPortableText, Tag } from '@components';
import { SVGArrowSolid } from '@components/svgs';
import { useAppDispatch, useArrayRef, useOnScreen } from '@hooks';
import { setLateralTexts } from '@store/dom-slice';
import { CollectionSlide, ProjectSectionProps } from '@types';
import classnames from 'classnames';
import { gsap } from 'gsap';
import React, { FC, MutableRefObject, useEffect, useRef } from 'react';
import SVGBottomShape from './components/svg-bottom-shape';
import SVGCenterShape from './components/svg-center-shape';
import SVGLineShape from './components/svg-line-shape';
import SVGMaskShape from './components/svg-mask-shape';
import SVGMaskedImage from './components/svg-masked-image';
import SVGTopShape from './components/svg-top-shape';

import styles from './project-section-mobile.module.scss';

const ProjectSectionMobile: FC<ProjectSectionProps> = ({
  parentRef,
  isMobile,
  isTablet,
  projectData,
}) => {
  const slidesRef: MutableRefObject<CollectionSlide[]> = useRef([
    {
      id: '01',
      backgroundColor: '#d2e8df',
      svgColor: '#E3FC5D',
      maskedImage: '/images/masked-image-1.png',
      titlePrimary: projectData.projectSlide1.titlePrimary,
      titleSecondary1: projectData.projectSlide1.titleSecondary1,
      titleSecondary2: projectData.projectSlide1.titleSecondary2,
      descriptionTitle: projectData.projectSlide1.descriptionTitle,
      descriptionText: projectData.projectSlide1.descriptionText,
      videoSrc: '/videos/YAEL_X_MANOR_PROJECT_0_1080p.mp4',
      mobileVideoSrc: '/videos/YAEL_X_MANOR_PROJECT_0_720.mp4',
      tagText: projectData.projectSlide1.tag,
    },
    {
      id: '02',
      backgroundColor: '#FDE4C6',
      svgColor: '#FF8D00',
      maskedImage: '/images/masked-image-2.png',
      titlePrimary: projectData.projectSlide2.titlePrimary,
      titleSecondary1: projectData.projectSlide2.titleSecondary1,
      titleSecondary2: projectData.projectSlide2.titleSecondary2,
      descriptionTitle: projectData.projectSlide2.descriptionTitle,
      descriptionText: projectData.projectSlide2.descriptionText,
      videoSrc: '/videos/YAEL_X_MANOR_PROJECT_1_1080p.mp4',
      mobileVideoSrc: '/videos/YAEL_X_MANOR_PROJECT_1_720.mp4',
      tagText: projectData.projectSlide2.tag,
    },
    {
      id: '03',
      backgroundColor: '#DDE4EC',
      svgColor: '#CCC9F4',
      maskedImage: '/images/masked-image-3.png',
      titlePrimary: projectData.projectSlide3.titlePrimary,
      titleSecondary1: projectData.projectSlide3.titleSecondary1,
      titleSecondary2: projectData.projectSlide3.titleSecondary2,
      descriptionTitle: projectData.projectSlide3.descriptionTitle,
      descriptionText: projectData.projectSlide3.descriptionText,
      videoSrc: '/videos/YAEL_X_MANOR_making_of_1080.mp4',
      mobileVideoSrc: '/videos/YAEL_X_MANOR_making_of_720.mp4',
      tagText: projectData.projectSlide3.tag,
    },
  ]);
  const slides = slidesRef.current;
  const sectionRef = useRef<HTMLDivElement>(null);
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const { refs: slideRefs, setRef: setSlideRef } = useArrayRef<HTMLDivElement>();
  const { refs: svgLineRefs, setRef: setSvgLineRef } = useArrayRef<SVGPathElement>();
  const { refs: svgTopRefs, setRef: setSvgTopRef } = useArrayRef<SVGSVGElement>();
  const isOnScreen = useOnScreen(sectionRef);
  const dispatch = useAppDispatch();

  useEffect(() => {
    gsap.set(svgLineRefs.current, { drawSVG: '0%' });
    for (const slide of slideRefs.current)
      gsap.set(slide, {
        height: `calc(${slide.offsetHeight}px + ${isMobile ? 40 : isTablet ? 75 : 80}rem`,
      });

    const slideInTls =
      parentRef?.current &&
      slideRefs.current.map((slideRef, index) =>
        gsap
          .timeline({
            scrollTrigger: {
              trigger: slideRef,
              start: '-=50% top',
              end: () => 'top top',
              scrub: 2,
              onEnter: () => gsap.to(svgLineRefs.current[index], { drawSVG: '100%', duration: 2 }),
            },
          })
          .call(() => {
            index !== 2 &&
              gsap.set(videoWrapperRef.current, {
                css: { width: `${videoWrapperRef.current?.offsetWidth}px` },
              });
          })
          .from(slideRef, { autoAlpha: 0 }, 0)
          .fromTo(
            parentRef.current,
            {
              backgroundColor: index === 0 ? 'transparent' : slides[index - 1].backgroundColor,
              immediateRender: false,
            },
            {
              backgroundColor: slides[index].backgroundColor,
            },
            0,
          )
          .fromTo(
            svgTopRefs.current[index],
            { opacity: 0, transform: 'rotate(-90deg)', yPercent: -200 },
            { opacity: 1, transform: 'rotate(0deg)', yPercent: 0 },
            0,
          ),
      );

    parentRef?.current && gsap.set(parentRef.current, { backgroundColor: 'transparent' });

    const transitionOutTl =
      parentRef?.current &&
      gsap
        .timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: '+=75% top',
            scrub: 2,
          },
        })
        .to(sectionRef.current, {
          autoAlpha: 0,
        })
        .to(parentRef.current, { backgroundColor: 'transparent' });

    return () => {
      transitionOutTl?.kill();
      slideInTls?.map((slideTl) => slideTl.kill());
    };
  }, [isMobile, isTablet, parentRef, slideRefs, slides, svgLineRefs, svgTopRefs]);

  useEffect(() => {
    isOnScreen &&
      dispatch(setLateralTexts({ leftText: 'lateral_texts.projects', rightText: '03' }));
  }, [isOnScreen, dispatch]);

  return (
    <section id="projects" data-section-id="#projects" ref={sectionRef} className={styles.section}>
      {slides.map((slide, index) => (
        <div
          key={index}
          id={`slide${index}Container`}
          ref={(ref) => ref && setSlideRef(ref)}
          className={styles.slide}>
          <div className={styles.topShape}>
            <SVGTopShape ref={(ref) => ref && setSvgTopRef(ref)} color={slide.svgColor} />
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.textWrapper}>
              <div className={styles.tag}>
                <Tag mode="light" text={slide.tagText} />
              </div>
              <div className={styles.titleLine}>
                <div className={classnames(styles.titleAppendix, styles.before)}>{slide.id}</div>
                <span className={styles.titlePrimary}>{slide.titlePrimary}&nbsp;</span>
                <span className={styles.titleSecondary}>{slide.titleSecondary1}</span>
              </div>
              <div className={styles.titleLine}>
                <span className={styles.titleSecondary}>{slide.titleSecondary2}</span>
                <span className={classnames(styles.titleAppendix, styles.after)}>{slide.id}</span>
              </div>
            </div>
            <div className={classnames(styles.textWrapper, styles.description)}>
              <div className={styles.arrow}>
                <SVGArrowSolid />
              </div>
              <div className={styles.descriptionTitle}>{slide.descriptionTitle}</div>
              <div className={styles.descriptionDivider}>
                <SVGLineShape ref={(ref) => ref && setSvgLineRef(ref)} />
              </div>
              <div>
                <SerializedPortableText value={slide.descriptionText} />
              </div>
            </div>
          </div>
          <div className={styles.mediaWrapper}>
            <div className={styles.maskedImage}>
              <div>
                <SVGMaskedImage id={`slide${index}`} maskedImage={slide.maskedImage} />
              </div>
            </div>
            <div className={styles.bottomShape}>
              <SVGBottomShape color={slide.svgColor} />
            </div>
            <div ref={videoWrapperRef} className={styles.videoWrapper}>
              <div className={styles.centerShape}>
                <SVGCenterShape color={slide.svgColor} />
              </div>
              <video
                autoPlay
                playsInline
                muted
                loop
                preload="true"
                className={classnames(styles.video, styles[`--${index}`])}>
                <source src={slide.mobileVideoSrc} />
              </video>
              <div className={styles.videoShape}>
                <SVGMaskShape
                  isMobile={true}
                  strokeWidth={isMobile ? '2' : '1'}
                  color={slide.svgColor}
                />
              </div>
            </div>
            <div className={styles.descriptionPager}>
              <span>0{index + 1}</span>&nbsp;of 03
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default React.memo(ProjectSectionMobile);
